import moment from "moment";
import { I18n } from "react-redux-i18n";
const MILLISECONDS_IN_6_HOURS = 21600000;
const MILLISECONDS_IN_1_HOURS = 3600000;
export default {
  _60TimeFormat: function (milliseconds) {
    let totalMinutes = Math.round(milliseconds / (1000 * 60));
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + "." + minutes;
  },
  _10TimeFormat: function (milliseconds) {
    let totalMinutes = milliseconds / (1000 * 60);
    let hours = totalMinutes / 60;

    return hours.toFixed(2);
  },
  getOnlyHoursMinutes: function (time) {
    let convert_time = moment(time).utcOffset(0);
    convert_time.set({ second: 0, millisecond: 0 });
    convert_time.toISOString();
    return new Date(convert_time);
  },
  params: function () {
    let search = window.location.search.substring(1);
    if (search === "") return {};

    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  },
  msToTime: function (duration) {
    let total_minutes = duration / (1000 * 60);
    var minutes = Math.floor(total_minutes % 60);
    var hours = Math.floor(total_minutes / 60);
    hours = hours < 0 ? hours : hours < 10 ? "0" + hours : hours;
    minutes = minutes < 0 ? minutes : minutes < 10 ? "0" + minutes : minutes;

    return {
      hours: hours,
      minutes: minutes,
    };
  },
  roundedFixed: function (timeObj) {
    let { root, range, time, type, key, checkin } = timeObj;
    let downRoundingTime = 0,
      upRoundingTime = 0;
    if (key === "time") {
      if (range == 15) {
        ///get duration time between time and root time
        var duration = moment.duration(moment(time).diff(moment(root)));
        duration = Math.floor(duration.asMinutes());
        //số lần chênh lệch khoảng thời gian làm tròn
        let count = Math.floor(duration / range);
        if (count == duration / range) {
          return moment(time).set("second", 0);
        }
        //lam tron len
        downRoundingTime = moment(root).add(count * range, "minutes");
        //lam tron xuong
        upRoundingTime = moment(downRoundingTime).add(range, "minutes");
      } else {
        if (range == 30 || range == 60) {
          ///get duration time between time and root time
          var duration = moment.duration(
            moment(time).add(1, "seconds").diff(moment(root))
          );
          duration = Math.floor(duration.asMinutes());
          //số lần chênh lệch khoảng thời gian làm tròn
          let count = Math.floor(duration / range);
          //lam tron len
          downRoundingTime = moment(root).add(count * range, "minutes");
          //lam tron xuong
          upRoundingTime = moment(downRoundingTime).add(range, "minutes");
        } else {
          downRoundingTime = moment(time);
          upRoundingTime = moment(downRoundingTime);
        }
      }
      if (type == 2) {
        return upRoundingTime;
      }
      if (type == 1) {
        return downRoundingTime;
      }
    } else if (key === "slideTime") {
      let roundRange = parseInt(range);
      // if(roundRange >= 60) {
      //     roundRange = 60
      // }
      roundRange = 15;
      var duration = moment.duration(
        moment(new Date(time)).diff(moment(new Date(root)))
      );
      duration = Math.floor(duration.asMinutes());
      let count = Math.floor(duration / roundRange);

      if (count == duration / roundRange) {
        return moment(time);
      }

      downRoundingTime = this.roundTo(
        time,
        moment.duration(roundRange, "minutes"),
        "floor"
      );
      upRoundingTime = this.roundTo(
        time,
        moment.duration(roundRange, "minutes"),
        "ceil"
      );

      if (checkin) {
        return upRoundingTime;
      } else {
        return downRoundingTime;
      }
    }
  },
  roundTo: function (date, duration, method) {
    return moment(Math[method](+date / +duration) * +duration);
  },
  roundToDown: function (date, range) {
    var minutes = 1000 * 60 * range;

    return new Date(Math.floor(date / minutes) * minutes);
  },

  getIntersectionTime: function (intervals) {
    let left = intervals[0].startTime;
    let right = intervals[0].endTime;
    for (let interval of intervals) {
      if (
        moment(interval.startTime) > moment(right) ||
        moment(interval.endTime) < moment(left)
      ) {
        return 0;
      } else {
        left = moment.max(moment(interval.startTime), moment(left));
        right = moment.min(moment(interval.endTime), moment(right));
      }
    }

    return right - left;
  },
  formatDayMonthYear: function (input, length) {
    input = "0000" + input;
    return input.slice(length);
  },
  dmyToString: function (day, month, year) {
    let dayStr = day.toString();
    dayStr = this.formatDayMonthYear(dayStr, -2);
    let monthStr = month.toString();
    monthStr = this.formatDayMonthYear(monthStr, -2);
    return year + "-" + monthStr + "-" + dayStr;
  },
  diffYearInInteger: function (startDate, endDate = new Date()) {
    return (
      ((endDate.getDate() - startDate.getDate()) / 30 +
        endDate.getMonth() -
        startDate.getMonth() +
        12 * (endDate.getFullYear() - startDate.getFullYear())) /
      12
    );
  },

  paidVacationDays: function (joiningDate) {
    let joinedYear = this.diffYearInInteger(new Date(joiningDate));

    if (joinedYear >= 6.5) return 20;
    if (joinedYear >= 5.5) return 18;
    if (joinedYear >= 4.5) return 16;
    if (joinedYear >= 3.5) return 14;
    if (joinedYear >= 2.5) return 12;
    if (joinedYear >= 1.5) return 11;
    if (joinedYear >= 0.5) return 10;
    return 0;
  },
  codeFormater: function (number) {
    number = number.replace(/[^\d]/g, "");
    if (number.length == 2) {
      number = number.replace(/(\d{2})/, "$1");
    } else if (number.length == 3) {
      number = number.replace(/(\d{2})(\d{1})/, "$1-$2");
    } else if (number.length == 4) {
      number = number.replace(/(\d{2})(\d{1})(\d{1})/, "$1-$2-$3");
    } else if (number.length == 5) {
      number = number.replace(/(\d{2})(\d{1})(\d{2})/, "$1-$2-$3");
    } else if (number.length == 6) {
      number = number.replace(/(\d{2})(\d{1})(\d{3})/, "$1-$2-$3");
    }
    return number;
  },
  calculationRestingTime: function (
    startTime,
    endTime,
    settingTime,
    slideWorking,
    isHoliday
  ) {
    let {
      roundEnd,
      roundEndType,
      roundStart,
      workingTimeEnd,
      workingtimeStart,
      roundStartType,
      key,
    } = settingTime;
    let _startTime = new Date(startTime),
      _endTime = new Date(endTime),
      totalTime = 0;

    let thisWeekDay = new Date(startTime).getDay();
    if (
      key == "slideTime" ||
      slideWorking ||
      (![undefined, null].includes(settingTime.holidayInProvision) &&
        settingTime.holidayInProvision
          .concat(settingTime.holidayOutProvision)
          .includes(thisWeekDay)) ||
      isHoliday
    ) {
      _startTime = this.roundedFixed({
        root: moment().startOf("day").toISOString(),
        time: new Date(startTime),
        range: roundStart,
        type: roundStartType,
        key: "slideTime",
        checkin: true,
      });
      _startTime = new Date(_startTime);
      if (endTime) {
        _endTime = this.roundedFixed({
          root: moment().startOf("day").toISOString(),
          time: new Date(endTime),
          range: roundEnd,
          type: roundEndType,
          key: "slideTime",
          checkin: false,
        });
        _endTime = new Date(_endTime);
        totalTime =
          this.getOnlyHoursMinutes(new Date(_endTime)) -
          this.getOnlyHoursMinutes(new Date(_startTime));
      }
    } else {
      // if (key == "time" && !slideWorking) {
      if (moment(endTime) > moment(workingTimeEnd)) {
        _endTime = this.roundedFixed({
          root: moment().startOf("day").toISOString(),
          time: new Date(endTime),
          range: roundEnd,
          type: roundEndType,
          key: settingTime.key,
        });
        _endTime = new Date(_endTime);
      }

      let checkUseRound = moment
        .duration(moment(workingtimeStart).diff(startTime))
        .asMinutes();
      if (moment(new Date(startTime)) < moment(new Date(workingtimeStart))) {
        if (checkUseRound > roundStart) {
          _startTime = this.roundedFixed({
            root: moment().startOf("day").toISOString(),
            time: new Date(startTime),
            range: roundStart,
            type: roundStartType,
            key: settingTime.key,
          });
          _startTime = new Date(_startTime);
        } else {
          _startTime = new Date(workingtimeStart);
        }
      } else {
        _startTime = new Date(startTime);
      }

      if (
        this.getOnlyHoursMinutes(endTime) -
          this.getOnlyHoursMinutes(startTime) >=
        0
      ) {
        totalTime =
          this.getOnlyHoursMinutes(_endTime) -
          this.getOnlyHoursMinutes(_startTime);
      }
    }

    if (parseFloat(totalTime) >= MILLISECONDS_IN_6_HOURS) {
      totalTime = parseFloat(totalTime) - parseFloat(MILLISECONDS_IN_1_HOURS);
    }

    return totalTime < 0 ? 0 : totalTime;
  },
  formatMessageError: function (err) {
    switch (err.status) {
      case 400: {
        if (err.message === "DayOff_Exist") {
          return I18n.t("Backend.DayOff.DayOff_Exist");
        }
        if (err.message === "Input_OT_Warn") {
          return I18n.t("Backend.Request.Input_OT_Warn");
        }
        if (err.message === "New_Req_Warn") {
          return I18n.t("Backend.Request.New_Req_Warn");
        }
        if (err.message === "Invalid_Req_Type") {
          return I18n.t("Backend.Request.Invalid_Req_Type");
        }
        if (err.message === "Req_DayOff_Warn") {
          return I18n.t("Backend.Request.Req_DayOff_Warn");
        }
        if (err.message === "Req_Holiday_Warn") {
          return I18n.t("Backend.Request.Req_Holiday_Warn");
        }
        if (err.message === "Choose_ReDate_Warn") {
          return I18n.t("Backend.Request.Choose_ReDate_Warn");
        }
        if (err.message === "Req_ReDate_Warn") {
          return I18n.t("Backend.Request.Req_ReDate_Warn");
        }
        if (err.message === "Input_Time_Warn") {
          return I18n.t("Backend.Request.Input_Time_Warn");
        }
        break;
      }
      case 404: {
        if (err.message === "No_Object") {
          return I18n.t("Backend.DbObject.No_Object");
        }
        break;
      }
      case 405: {
        if (err.message === "User_Level_Warn") {
          return I18n.t("Backend.Request.User_Level_Warn");
        }
        if (err.message === "Slide_Time_User") {
          return I18n.t("Backend.Request.Slide_Time_User");
        }
        break;
      }
      case 422: {
        if (err.message === "Id_Required") {
          return I18n.t("Backend.DbObject.Id_Required");
        }
        break;
      }
      default:
        return `Response: [${err.status}] ${err.message}`;
    }
  },
  verifyAlcohol: function (value) {
    let alcohol = parseFloat(value);

    if (alcohol >= 0.25) return "high";
    if (alcohol >= 0.15 && alcohol < 0.25) return "medium";
    return "none";
  },
  validateAlcohol: function (value) {
    if (value === "" || value == null) return;

    const floatValue = parseFloat(value);
    if (
      value.startsWith("-") ||
      isNaN(floatValue) ||
      floatValue < 0 ||
      (!/^\d*\.\d*$/.test(value) && floatValue < 1 && floatValue != 0)
    ) {
      return I18n.t("Form.min").replace("${arguments[0]}", "0");
    } else if (floatValue > 1) {
      return I18n.t("Form.max").replace("${arguments[0]}", "1");
    }
  },
};
