import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade';
import {
    Card,
    Button,
    Dialog,
    DialogContent,
    Grid,
    DialogTitle,
} from '@material-ui/core'
import moment from 'moment'
import { I18n } from 'react-redux-i18n';
import _ from 'lodash'
import FilterFrom from './components/FilterFrom'
import { Form, TextField, Validation, CheckboxField } from 'components/Forms'
import ExportExcel from 'components/ExportExcel/ExportExcel'
import http from '../../helpers/http'

const GridTable = React.lazy(() => import('components/Table/GridTable'))
const styles = theme => ({
    gridTable: {
        height: "calc(100vh - 200px)"        
    }
});
class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            dataEdit: {},
            reload: false,
            isFomat60: true,
            isRounding: false,
            exportAll: false
        }
        this.validate = {
            memo: [
              Validation.maxLength(20, I18n.t("Form.mangleng20"))
            ]
        }
      
        this.table = {
            columns: [
                {
                    name: 'date',
                    filterable: false,
                    title: I18n.t("Input.date"),
                    type: "text",
                    formatterComponent: (data) => {  
                        let inputDate = _.get(data.row, "date", false)      
                        return inputDate ?  inputDate : I18n.t('Table.header.labor.laborTotal')
                    },
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: "weekday",
                    filterable: false,
                    title: '曜日',
                    type: "text"
                },
                {
                    name: 'userCode',
                    title: '社員コード',
                    type: "text",
                },
                {
                    name: 'userName',
                    title: '社員名',
                    type: "text",
                },
                {
                    name: 'dateStatus',
                    title: '公休',
                    type: "text",
                },
                {
                    name: '_startTime',
                    type: "date",
                    title: I18n.t('Table.header.hour.roundedStartTime'),
                    filterable: false,
                    formatterComponent: (data) => {
                        let _startTime = _.get(data.row, '_startTime', null)
                        if (_startTime) {
                            return _startTime
                        } else {
                           return ''
                        }
                     },
                },
                {
                    name: '_endTime',
                    type: "text",
                    title: I18n.t('Table.header.hour.roundedEndTime'),
                    filterable: false,
                    formatterComponent: (data) => {
                        let _endTime = _.get(data.row, '_endTime', null)
                        if (_endTime) {
                            return _endTime
                        } else {
                           return ''
                        }
      
                     },
                },
                {
                    name: 'constructions.code',
                    title: I18n.t("Table.header.construction.code"),
                    type: "text",
                    filterable: false,
                    sortable: false,
                    excelArray: {
                        field: "constructions",
                        childField: "code"
                    },
                    formatterComponent: (data) => {
                        let constructions = this.customColumn(data, "row.constructions", [])
                        return constructions.map((con, index) => {
                            if (con) {
                                return <p key={index}>{con.code.replace(/-/g, "")}</p>
                            }

                        })
                    },
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: 'constructions.name',
                    type: "text",
                    title: I18n.t("Table.header.construction.name"),
                    filterable: false,
                    sortable: false,
                    excelArray: {
                        field: "constructions",
                        childField: "name"
                    },
                    formatterComponent: (data) => {
                        let constructions = this.customColumn(data, "row.constructions", [])
                        return constructions.map((con, index) => {
                            if (con) {
                                return <p key={index} >{con.name}</p>
                            }
                        })
                    },
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: 'calculateDayWork',
                    type: "number",
                    title: I18n.t('Table.header.hour.totalDays'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.calculateDayWork")
                    }
                },
                {
                    name: 'normalDays.standardHour',
                    type: "number",
                    title: I18n.t('Table.header.hour.standardHour'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.normalDays.standardHour")
                    },
                    style: {
                        backgroundColor: "#7FDBFF"
                    },
                    printStyle: {
                        textAlign: 'center',
                    },
                },
                {
                    name: 'workNightHour',
                    type: "number",
                    title: I18n.t('Table.header.hour.workNightHour'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.workNightHour")
                    },
                    style: {
                        backgroundColor: "#7FDBFF"
                    },
                    printStyle: {
                        textAlign: 'center',
                    },
                },
                {
                    name: 'workingOfferOvertime',
                    type: "number",
                    title: I18n.t('Table.header.hour.workingOfferOvertime'),
                    formatterComponent: () => {
                        return ""
                    },
                    filterable: false
                },
                // {
                //     name: 'normalDays.morningOT',
                //     type: "number",
                //     title: I18n.t('Table.header.hour.morningOT'),
                //     filterable: false,
                //     formatterComponent: (data) => {
                //         return this.customHourColumn(data, "row.normalDays.morningOT")
                //     },
                //     style: {
                //         backgroundColor: "#7FDBFF"
                //     },
                //     printStyle: {
                //         textAlign: 'center',
                //     },
                // },
                {
                    name: 'normalDays.normalOT',
                    type: "number",
                    title: I18n.t('Table.header.hour.normalOT'),
                    filterable: false,
                    formatterComponent: (data) => {
                        // switch (data.row.dateType) {
                        //     case "nonStatutoryDays":
                        //         return this.customHourColumn(data, "row.nonStatutoryDays.normalOT")
                        //     case "statutoryDays":
                        //         return this.customHourColumn(data, "row.statutoryDays.normalOT")
                        //     default:
                                return this.customHourColumn(data, "row.normalDays.normalOT")
                        // }
                    },
                    style: {
                        backgroundColor: "#7FDBFF"
                    },
                    printStyle: {
                        textAlign: 'center',
                    },
                },
                // {
                //     name: 'normalDays.nightOT',
                //     type: "number",
                //     title: I18n.t('Table.header.hour.nightOT'),
                //     filterable: false,
                //     formatterComponent: (data) => {
                //         return this.customHourColumn(data, "row.normalDays.nightOT")
                //     },
                //     style: {
                //         backgroundColor: "#7FDBFF",
                //     },
                //     printStyle: {
                //         textAlign: 'center',
                //     },
                // },
                {
                    name: 'workingInProvisionHoliday',
                    type: 'number',
                    title: '法定内休日出勤日数'
                },
                {
                    name: 'statutoryDays.standardHour',
                    type: "number",
                    title: I18n.t('Table.header.hour.statutoryDays_standardHour'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.statutoryDays.standardHour")
                    },
                    printStyle: {
                        textAlign: 'center',
                    },
                },
                // {
                //     name: 'statutoryDays.workNightHour',
                //     type: "number",
                //     title: I18n.t('Table.header.hour.statutoryDays_workNightHour'),
                //     filterable: false,
                //     formatterComponent: (data) => {
                //         return this.customHourColumn(data, "row.statutoryDays.workNightHour")
                //     },
                //     printStyle: {
                //         textAlign: 'center',
                //     },
                // },
                {
                    name: 'workingOutProvisionHoliday',
                    type: 'number',
                    title: '法定外休日出勤日数',
                    style: {
                        backgroundColor: "#7FDBFF"
                    }
                },
                {
                    name: 'nonStatutoryDays.standardHour',
                    type: "number",
                    title: I18n.t('Table.header.hour.nonStatutoryDays_standardHour'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.nonStatutoryDays.standardHour")
                    },
                    style: {
                        backgroundColor: "#7FDBFF"
                    },
                    printStyle: {
                        textAlign: 'center',
                    },
                },
                {
                    name: "alcohol",
                    title: I18n.t("Table.header.alcohol_count"),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) =>
                      data.row?.alcohol ? data.row.alcohol : null,
                  },
                  {
                    name: "intoxicated_mouth_less",
                    title: I18n.t("Table.header.intoxicated_mouth_less"),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) =>
                      data.row?.intoxicated_mouth === "medium" ? "✅" : null,
                  },
                  {
                    name: "intoxicated_mouth_more",
                    title: I18n.t("Table.header.intoxicated_mouth_more"),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) =>
                      data.row?.intoxicated_mouth === "high" ? "✅" : null,
                  },

                // {
                //     name: 'nonStatutoryDays.workNightHour',
                //     type: "number",
                //     title: I18n.t('Table.header.hour.nonStatutoryDays_workNightHour'),
                //     filterable: false,
                //     formatterComponent: (data) => {
                //         return this.customHourColumn(data, "row.nonStatutoryDays.workNightHour")
                //     },
                //     style: {
                //         backgroundColor: "#7FDBFF"
                //     },
                //     printStyle: {
                //         textAlign: 'center',
                //     },
                // },
                {
                    name: 'driving_length_on_working',
                    filterable: false,
                    type: "number",
                    title: I18n.t("Table.header.timekeeping.driving_length_on_working"),
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.driving_length_on_working")
                    },
                },
                {
                    name: 'driving_length_on_private',
                    filterable: false,
                    type: "number",
                    title: I18n.t("Table.header.timekeeping.driving_length_on_private"),
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.driving_length_on_private")
                    },
                },
                {
                    name: 'fuel_supply_amount',
                    type: "number",
                    filterable: false,
                    title: I18n.t("Table.header.timekeeping.fuel_supply_amount"),
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.fuel_supply_amount")
                    },
                },
                {
                    name: 'lateWork',
                    type: "number",
                    title: I18n.t('Table.header.hour.lateWork'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.lateWork")
                    },
                    printStyle: {
                        textAlign: 'center',
                    },
                },
                {
                    name: 'absent',
                    type: "number",
                    title: I18n.t('Table.header.hour.absent'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.absent")
                    },
                    printStyle: {
                        textAlign: 'center',
                    },
                },
                {
                    name: 'workingInHolidays',
                    type: "number",
                    title: I18n.t('Table.header.hour.workingInHolidays'),
                    filterable: false
                },
                {
                    name: 'countCompensatedInProvision',
                    type: "number",
                    title: I18n.t('Table.header.hour.countCompensatedInProvision'),
                    filterable: false
                },
                {
                    name: 'countCompensatedOutProvision',
                    type: "number",
                    title: I18n.t('Table.header.hour.countCompensatedOutProvision'),
                    filterable: false
                },
                {
                    name: 'rosteredDayOff',
                    type: "number",
                    title: I18n.t('Table.header.hour.rosteredDayOff'),
                    filterable: false
                },
                {
                    name: 'contentRequest',
                    type: "text",
                    title: I18n.t("Table.header.hour.contentRequest"),
                    filterable: false,
                    sortable: false,
                    excelArray: {
                        field: "requestDayApproved",
                        childField: "dayWorkOnDayOff"
                    },
                    formatterComponent: (data) => {
                        let requestDayApproved = this.customColumn(data, "row.requestDayApproved", [])
                        return requestDayApproved.map((con, index) => {
                            if (con) {
                                return <p key={index} >{con.dayWorkOnDayOff}</p>
                            }
                        })
                    },
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: 'usedPaidHoliday',
                    type: "number",
                    title: I18n.t('Table.header.hour.usedPaidHoliday'),
                    filterable: false
                },
                {
                    name: 'usedHolidayInHour',
                    type: "number",
                    title: I18n.t('Table.header.hour.usedHolidayInHour'),
                    filterable: false
                },
                {
                    name: 'remainedPaidHoliday',
                    type: "number",
                    title: I18n.t('Table.header.hour.remainedPaidHoliday'),
                    filterable: false
                },
                {
                    name: 'usedSpecialHolidayInDay',
                    type: "number",
                    title: I18n.t('Table.header.hour.usedSpecialHolidayInDay'),
                    filterable: false
                },
                {
                    name: 'usedSpecialHolidayInHour',
                    type: "number",
                    title: I18n.t('Table.header.hour.usedSpecialHolidayInHour'),
                    filterable: false
                },
                {
                    name: 'totalTime',
                    type: "number",
                    title: I18n.t('Table.header.hour.totalTime'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.totalTime")
                    }
                },
                {
                    name: 'allHours',
                    type: "number",
                    title: I18n.t('Table.header.hour.allHours'),
                    filterable: false,
                    style: {
                        textAlign: "right"
                    },
                    printStyle: {
                        textAlign: 'right',
                    },
                },
                {
                    name: 'memo',
                    type: "text",
                    title: I18n.t('Table.header.memo'),
                    filterable: false,
                    printStyle: {
                        textAlign: 'center',
                    },
                },
                {
                    name: 'memo_calendar',
                    type: "text",
                    title: I18n.t('Table.header.memo_calendar'),
                    filterable: false,
                    hidden: true,
                    printStyle: {
                        textAlign: 'center',
                    },
                    formatterComponent: (data) => {
                        return this.customHourColumn(data, "row.memo")
                    }
                },
                {
                    name: 'memo_request',
                    type: "text",
                    title: I18n.t('Table.header.memo_request'),
                    filterable: false,
                    hidden: true,
                    printStyle: {
                        textAlign: 'center',
                    }
                },
                {
                    name: '_id',
                    type: "text",
                    title: I18n.t('Table.header.action'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customActionColumn(data)
                    }
                }
            ],
            defaultSort: [{ columnName: "date", direction: "desc" }],
            //tên các column name không muốn xuất file
            ignoreExcelColumns: ["index", "_id", "memo"],
            ignorePrintColumns: ["_id", "memo_request", "memo_calendar"],
            tableColumnExtensions: [
                { columnName: 'index', align: 'center', wordWrapEnabled: true },
                { columnName: 'constructions.code', align: 'center', wordWrapEnabled: true },
                { columnName: 'constructions.name', align: 'center', wordWrapEnabled: true },
                { columnName: 'normalDays.standardHour', align: 'center', wordWrapEnabled: true },
                { columnName: 'workNightHour', align: 'center', wordWrapEnabled: true },
                { columnName: 'normalDays.normalOT', align: 'center', wordWrapEnabled: true },
                { columnName: 'statutoryDays.standardHour', align: 'center', wordWrapEnabled: true },
                { columnName: 'statutoryDays.morningOT', align: 'center', wordWrapEnabled: true },
                { columnName: 'statutoryDays.normalOT', align: 'center', wordWrapEnabled: true },
                { columnName: 'statutoryDays.nightOT', align: 'center', wordWrapEnabled: true },
                { columnName: 'nonStatutoryDays.standardHour', align: 'center', wordWrapEnabled: true },
                { columnName: 'nonStatutoryDays.morningOT', align: 'center', wordWrapEnabled: true },
                { columnName: 'nonStatutoryDays.normalOT', align: 'center', wordWrapEnabled: true },
                { columnName: 'nonStatutoryDays.nightOT', align: 'center', wordWrapEnabled: true },
                { columnName: 'lateWork', align: 'center', wordWrapEnabled: true },
                { columnName: 'absent', align: 'center', wordWrapEnabled: true },
                { columnName: 'calculateDayWork', align: 'center', wordWrapEnabled: true },
                { columnName: 'totalTime', align: 'center', align: 'center', wordWrapEnabled: true },
                { columnName: 'driving_length_on_working', align: 'center', wordWrapEnabled: true },
                { columnName: 'driving_length_on_private', align: 'center', wordWrapEnabled: true },
                { columnName: 'fuel_supply_amount', align: 'center', wordWrapEnabled: true },
                { columnName: 'memo', align: 'center', wordWrapEnabled: true },
                { columnName: '_id', align: 'center', wordWrapEnabled: true },
                { columnName: "alcohol", wordWrapEnabled: true },
                { columnName: "intoxicated_mouth_less", wordWrapEnabled: true },
                { columnName: "intoxicated_mouth_more", wordWrapEnabled: true },
            ],
            //set độ rộng của excel và của bảng gridTable
            columnWidths: [
                {
                    name: 'date',
                    width: 150
                },
                {
                    name: 'userCode',
                    width: 100
                },
                {
                    name: 'userName',
                    width: 120
                },
                {
                    name: 'dateStatus',
                    width: 70
                },
                {
                    name: 'weekday',
                    width: 100
                },
                {
                    name: 'workingInProvisionHoliday',
                    width: 180
                },
                {
                    name: 'workingOutProvisionHoliday',
                    width: 180
                },
                {
                    name: 'constructions.code',
                    width: 150
                },
                {
                    name: 'constructions.name',
                    width: 280
                },
                {
                    name: 'normalDays.standardHour',
                    width: 180
                },
                {
                    name: 'workNightHour',
                    width: 180
                },
                {
                    name: 'normalDays.normalOT',
                    width: 180
                },
                {
                    name: 'statutoryDays.standardHour',
                    width: 180
                },
                {
                    name: 'statutoryDays.morningOT',
                    width: 180
                },
                {
                    name: 'statutoryDays.normalOT',
                    width: 180
                },
                {
                    name: 'statutoryDays.nightOT',
                    width: 180
                },
                {
                    name: "alcohol",
                    width: 100,
                },
                {
                    name: "intoxicated_mouth_less",
                    width: 110,
                },
                {
                    name: "intoxicated_mouth_more",
                    width: 130,
                },
                {
                    name: 'nonStatutoryDays.standardHour',
                    width: 180
                },
                {
                    name: 'nonStatutoryDays.morningOT',
                    width: 180
                },
                {
                    name: 'nonStatutoryDays.normalOT',
                    width: 180
                },
                {
                    name: 'nonStatutoryDays.nightOT',
                    width: 180
                },
                {
                    name: 'lateWork',
                    width: 180
                },
                {
                    name: 'absent',
                    width: 180
                },
                {
                  name: 'workingInHolidays',
                  width: 180
                },
                {
                  name: 'workingOfferOvertime',
                  width: 180
                },
                {
                  name: '_startTime',
                  width: 130
                },
                {
                  name: '_endTime',
                  width: 130
                },
                {
                  name: 'usedPaidHoliday',
                  width: 130
                },
                {
                  name: 'remainedPaidHoliday',
                  width: 130
                },
                {
                  name: 'rosteredDayOff',
                  width: 200
                },
                {
                    name: 'countCompensatedInProvision',
                    width: 200
                },
                {
                    name: 'countCompensatedOutProvision',
                    width: 200
                },
                {
                    name: 'contentRequest',
                    width: 150
                },
                {
                  name: 'usedSpecialHolidayInDay',
                  width: 100
                },
                {
                    name: 'calculateDayWork',
                    width: 150
                },
                {
                    name: 'totalTime',
                    width: 180
                },
                {
                    name: 'driving_length_on_working',
                    width: 150
                },
                {
                    name: 'driving_length_on_private',
                    width: 150
                },
                {
                    name: 'fuel_supply_amount',
                    width: 150
                },
                {
                    name: 'usedHolidayInHour',
                    width: 150
                },
                {
                    name: 'usedSpecialHolidayInHour',
                    width: 150
                },
                {
                    name: 'allHours',
                    width: 150
                },
                {
                    name: 'memo',
                    width: 150
                },
                {
                    name: 'memo_calendar',
                    width: 150
                },
                {
                    name: 'memo_request',
                    width: 150
                },
                {
                    name: '_id',
                    width: 120
                }
            ]
        }
        this.onCancel = this.onCancel.bind(this)
        this.onShow = this.onShow.bind(this)
        this.onHide = this.onHide.bind(this)
        this.ConfirmDialog = null
        this.renderToolbarActions = this.renderToolbarActions.bind(this)
    }

    isFormat60 = (bool) => {
        this.setState({
            isFomat60: bool
        })
    }

    customHourColumn(data, path) {
        let totalRow = this.getData(data, 'row.totalRow', false)
      
        if (totalRow) {
            data = this.getData(data, path, '0')
        } else {
            data = this.getData(data, path, '0')
        }
        return String(data)
    }

    customColumn(data, path, defaultValue = '') {
        data = this.getData(data, path, defaultValue)
        return data
    }

    onCancel() {
        this.onHide()
    }

    onShow (dataEdit) {
        this.setState({ open: true, dataEdit: dataEdit })
        this.setState({ reload: !this.state.reload })
    }

    onHide() {
        this.setState({ open: false })
    }

    customActionColumn(data) {
        let value = _.get(data, 'value', undefined)
        let dataEdit = _.get(data, 'row', {})
        const { classes } = this.props
        let isPaidHoliday =_.get(data, 'row.isPaidHoliday', '')
        if(value === undefined){
            return ''
        }
        return (
            <span>
                <Button 
                    variant='contained' color="primary"  
                    className={classes.button} 
                    onClick={() => this.onShow(dataEdit)} 
                    disabled={isPaidHoliday ? true : false}
                > 
                    {I18n.t("Button.edit")}
                </Button>
            </span>
        )
    }

    getHeaderText() {
        let print_timekeepings = JSON.parse(localStorage.getItem("print_timekeepings")) || {};
        return `${I18n.t('Input.month')}: ${print_timekeepings.month}, ${I18n.t('Table.header.user.code')}:  ${print_timekeepings.code}, ${I18n.t('Table.header.user.name')}: ${print_timekeepings.name}`
    }

    getExcelName = () => {
      if (this.state.exportAll) {
        return `個人別勤怠日計表-${moment(this.filterForm.state.dateRange.startDate).format('YYYY-MM')}`
      } else {
        let print_timekeepings = JSON.parse(localStorage.getItem("print_timekeepings")) || {};
        return `${print_timekeepings.code}_${print_timekeepings.name}`
      }
    }

    onExportAll = (exportType = 'xlsx', cb) => {
        let promises = [], that = this
        const { users } = this.props

        let response = http.get('/api/v1/hours/fetchAllUserTimekeeping', {
            startDate: that.filterForm.state.dateRange.startDate,
            endDate: that.filterForm.state.dateRange.endDate,
            time_keeping: 'true',
            unique: 'true',
            checkTimeZoneFE: localStorage.getItem('frontTimeZone')
        })
        promises.push(response)

        let exportData = [], userInfos = []
        Promise.all(promises).then(res => {
            res[0]?.data?.data?.forEach(end_data => {
                let { user, data } = end_data
                userInfos.push([[user.name, `社員コード：${user.code}`]])
                exportData.push(data)
            })
            cb('csv', userInfos, exportData)
        })
    }

    renderToolbarActions() {
        let { data = [] } = this.props.data
        //tô đậm dòng tổng;
        if (data.length) {
            data[data.length - 1].style = { fontWeight: 'bold' }
        }

        const { ignoreExcelColumns, columnWidths, columns, ignorePrintColumns } = this.table
        const headerText = this.getHeaderText();
        return [
            <CheckboxField
                key='export_all'
                label='すべて'
                name="export_all"
                checked={this.state.exportAll}
                onChange={value => {
                    this.setState({
                        exportAll: value
                    })
                }}
            />,
            <ExportExcel
                ignoreExcelColumns={ignoreExcelColumns}
                // unwind={{ field: "constructions", groupBy: "date", nonMergeField: ['constructions.code', 'constructions.name'] }}
                name={this.getExcelName}
                data={data}
                columnWidths={columnWidths}
                columns={columns}
                onClick={this.state.exportAll ? this.onExportAll : null}
                key="exportExcel"
                type="csv" />
        ]
    }

    renderDialog(classes, onSubmitDistanceFuel) {
        let { dataEdit } = this.state
        let date = _.get(dataEdit, 'date', '')
        let driving_length_on_working = _.get(dataEdit, 'driving_length_on_working', '')
        let driving_length_on_private = _.get(dataEdit, 'driving_length_on_private', '')
        let fuel_supply_amount = _.get(dataEdit, 'fuel_supply_amount', '')
        let memo = _.get(dataEdit, 'memo', '')
        let historyId = _.get(dataEdit, 'historyId', '')
        return (
            <Card>
                 <Dialog
                    fullWidth={true}
                    onClose={this.onCancel}
                    open={this.state.open}
                    maxWidth='sm'
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{date}</DialogTitle>
                    <DialogContent>
                        <Form className={classes.form} onSubmit={onSubmitDistanceFuel}>
                            <Grid container >
                                <TextField
                                    name="_id"
                                    value={historyId}
                                    type="hidden"
                                />
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={I18n.t("Table.header.timekeeping.driving_length_on_working")}
                                        name="driving_length_on_working"
                                        value={driving_length_on_working}
                                        type="number"
                                        inputProps={{step: 'any'}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={I18n.t("Table.header.timekeeping.driving_length_on_private")}
                                        name="driving_length_on_private"
                                        value={driving_length_on_private}
                                        type="number"
                                        inputProps={{step: 'any'}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={I18n.t("Table.header.timekeeping.fuel_supply_amount")}
                                        name="fuel_supply_amount"
                                        value={fuel_supply_amount}
                                        type="number"
                                        inputProps={{step: 'any'}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={I18n.t("Table.header.timekeeping.memo")}
                                        name="memo"
                                        value={memo}
                                        type="text"
                                        validate={this.validate.memo}
                                    />
                                </Grid>
                                <Button onClick={this.onHide} color="primary">
                                    {I18n.t("Button.cancel")}
                                </Button>
                                <Button type="submit" onClick={this.onCancel} color="primary" autoFocus>
                                    {I18n.t("Button.submit")}
                                </Button>
                            </Grid>
                        </Form>

                    </DialogContent>
                </Dialog>

            </Card>
        )
    }

    onRef = (ref) => {
      this.filterForm = ref
    }

    render() {
        const { data = {}, classes, users = [], onSubmit, _isRound, isRound, onSubmitDistanceFuel } = this.props
        
        let rows = data.data || []
       
        // let defaultColumnWidths = this.table.columnWidths.length > 0 ? this.table.columnWidths : 0   
        return (
         
            <PaperFade showloading="true">
                {
                    this.renderDialog(classes, onSubmitDistanceFuel)
                }
                <FilterFrom onRef={this.onRef} isFormat60={this.isFormat60} isRound={isRound} _isRound={_isRound} users={users} onSubmit={onSubmit} />
                <div id="boldLastRow" style={{display:"inlineBlock"}} >
                    <GridTable
                        ref={(ref) => this.gridTable = ref}
                        className={classes.gridTable}
                        onFetchData={this.props.onFetchData}
                        onRefTable={this.props.onRefTable}
                        columns={this.table.columns}
                        defaultSort={this.table.defaultSort}
                        height="auto"
                        tableActions={this.renderToolbarActions}
                        defaultColumnWidths={this.table.columnWidths}
                        tableColumnExtensions={this.table.tableColumnExtensions}
                        rows={[...(rows || [])]}
                        filterRowHiding={true}
                        pageHiding={true}
                        hiddenColumnMonth={true}
                    />
                </div>
            </PaperFade>
          
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(Index))